<template>
  <v-app id="app">
    <v-app-bar
      app
      :color="primaryColor"
      dark
      v-if="isShowAppBar"
      class="main-app-bar"
    >
      <v-app-bar-nav-icon
        v-if="renderSideBar"
        @click="toggleSidebar"
      />
      <v-toolbar-title>{{ appBarTitle }}</v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <v-app-bar
      app
      fixed
      :color="primaryColor"
      dark
      v-if="user && showAppBar && !inApp"
    >
      <v-app-bar-nav-icon
        v-if="!showAppBarSearchField && !inApp"
        @click="toggleSidebar"
      />

      <v-toolbar-title v-if="!showAppBarSearchField && !inApp">
        {{ appBarTitle }}
      </v-toolbar-title>

      <v-combobox
        v-if="showAppBarSearchField && !inApp"
        solo
        autofocus
        :label="$t('search...')"
        append-icon="mdi-search"
        hide-details
        :dark="false"
        v-model="searchQuery"
        hide-no-data
        chips
        deletable-chips
        :items="appBarSearchChips"
        item-text="label"
        item-value="key"
        multiple
      >
        <template v-slot:selection="data">
          <v-menu
            :key="JSON.stringify(data.item)"
            v-model="menu"
            bottom
            right
            transition="scale-transition"
            origin="top left"
          >
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                @click:close="data.parent.selectItem(data.item)"
              >
                <v-avatar
                  class="accent white--text"
                  left
                >
                  Q
                </v-avatar>
                {{ data.item.value ? data.item.value : this.$t('setValue') }}
              </v-chip>
            </template>
            <v-card width="300">
              <v-list
                dense
                dark
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.label }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t('selectAtLeastOne') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      @click="menu = false"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-list dense>
                <v-list-item
                  :key="option.key"
                  v-for="option in data.item.options"
                  @click="() => {}"
                >
                  <v-list-item-subtitle>
                    {{
                      option.label
                    }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
      </v-combobox>

      <v-spacer v-if="!showAppBarSearchField && !inApp" />

      <v-btn
        v-if="appBarChangeDisplay && !inApp"
        @click="appBarChangeDisplay.callback"
        icon
      >
        <v-icon v-if="!!appBarChangeDisplay.icon">
          {{ appBarChangeDisplay.icon }}
        </v-icon>
        <v-icon v-else>
          mdi-eye
        </v-icon>
      </v-btn>

      <v-btn
        v-if="appBarExport && !inApp"
        @click="appBarExport.callback"
        icon
      >
        <v-icon>mdi-export</v-icon>
      </v-btn>

      <v-btn
        v-if="appBarMarketing && !inApp"
        @click="appBarMarketing.callback"
        icon
      >
        <v-icon>mdi-facebook-messenger</v-icon>
      </v-btn>

      <v-btn
        v-if="!showAppBarSearchField && !inApp"
        @click="toggleSearch"
        icon
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn
        v-if="showAppBarSearchField && !inApp"
        @click="closeSearch"
        icon
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn
        v-if="!inApp"
        icon
        to="/account"
      >
        <v-badge
          v-if="!showAppBarSearchField"
          color="green"
          overlap
          dot
          bottom
        >
          <v-avatar
            color="grey"
            size="32"
          >
            <span class="white--text">{{
              user.displayName.getInitials()
            }}</span>
          </v-avatar>
        </v-badge>
      </v-btn>

      <v-btn
        v-if="!showAppBarSearchField && !inApp"
        icon
        @click="showPlatforms = !showPlatforms"
      >
        <v-icon>mdi-apps</v-icon>
      </v-btn>

      <template
        style="width: 80%"
        v-if="(appBarTabs && appBarTabs.length > 0) || appBarFAB !== null"
        v-slot:extension
      >
        <v-tabs
          show-arrows
          v-if="appBarTabs && appBarTabs.length > 0"
          v-model="selectedAppBarTab"
          align-with-title
          style="width: calc(100vw - 80px); flex: none"
        >
          <AppBarTab
            v-for="tab in appBarTabs"
            :key="tab.key"
            :tab="tab"
          />
        </v-tabs>
        <v-btn
          v-if="appBarFAB !== null"
          fab
          :color="appBarFAB.color || accentColor"
          bottom
          right
          absolute
          @click="appBarFAB.callback"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-if="!inApp && showPlatforms"
      v-model="showPlatforms"
      app
      right
      disable-resize-watcher
    >
      <v-toolbar
        dark
        :color="primaryColor"
      >
        <v-toolbar-title>{{ $t('platform') }}</v-toolbar-title>
      </v-toolbar>

      <v-divider />

      <v-list>
        <template v-for="(platform, index) in platforms">
          <v-list-item
            :href="platform.path"
            target="_blank"
            :link="true"
            :key="platform.name"
          >
            <v-list-item-avatar>
              <v-img :src="platform.icon" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title> {{ platform.name }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="index" />
        </template>
      </v-list>
    </v-navigation-drawer>
    <Sidebar
      v-if="renderSideBar && !inApp"
      app
      v-model="showSidebar"
    />
    <v-main :style="computedMainStyle">
      <v-alert
        tile
        v-if="canEnableSalesCloudPay"
        type="info"
        prominent
      >
        <v-row dense align="center">
          <v-col class="grow">
            Start processing payments with SalesCloud Pay.
          </v-col>
          <v-col class="shrink">
            <v-btn :loading="enablingSalesCloudPay" :disabled="enablingSalesCloudPay" @click="enableSalesCloudPay">Enable</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert
        tile
        type="warning"
        class="mb-0"
        v-if="
          billingAccountState === 'risk' &&
            !$route.path.includes('billing') &&
            false
        "
      >
        Please pay outstanding invoices to keep using SalesCloud.
        <v-btn to="/billing">
          Pay Now
        </v-btn>
      </v-alert>
      <router-view :key="$route.fullPath" />
      <form
        action="https://service-xlsx.salescloud.is"
        accept-charset="UTF-8"
        method="post"
        target="_blank"
        ref="exportXLSForm"
        style="display: none"
        @submit="xlsSubmit"
      >
        <input
          type="hidden"
          name="fileName"
          :value="exportFileName"
        >
        <input
          type="hidden"
          name="html"
          :value="exportXlsHtml"
        >
        <input
          type="hidden"
          name="isCompressed"
          :value="exportIsCompressed"
        >
      </form>
      <form
        action="https://service-pdf.salescloud.is"
        accept-charset="UTF-8"
        method="post"
        target="_blank"
        ref="exportPDFForm"
        style="display: none"
        @submit="pdfSubmit"
      >
        <input
          type="hidden"
          name="fileName"
          :value="exportFileName"
        >
        <input
          type="hidden"
          name="html"
          :value="exportPdfHtml"
        >
      </form>
      <div id="context-overlay">
        <ContextOverlay
          @closed="closeExportObjects"
          v-if="contextOverlay !== null && contextOverlay !== undefined"
          :component="contextOverlay.component"
          :props="contextOverlay.props"
          :key="contextOverlay.component"
        />
      </div>
      <div id="generic-dialog">
        <GenericDialog
          v-if="genericDialog !== null"
          :key="genericDialog.component"
          :component="genericDialog.component"
          :props="genericDialog.props"
        />
      </div>
      <div id="mutation-dialog">
        <MutationDialog
          v-if="dataToMutate !== null && dataToMutate !== undefined"
          :form-component="dataToMutate.formComponent"
          :object-to-mutate="dataToMutate.objectToMutate"
          :save-callback="dataToMutate.saveCallback"
          :title="dataToMutate.title"
          :key="dataToMutate.formComponent"
          :preview-url="dataToMutate.previewUrl"
          :preview-style="dataToMutate.previewStyle"
          :prevent-preview-properties="dataToMutate.preventPreviewProperties"
          :preview-type="dataToMutate.previewType"
          :persistent="dataToMutate.persistent"
        />
      </div>
      <CallToAction
        :call-to-action="selectedCallToAction"
        v-if="selectedCallToAction !== null && selectedCallToAction !== undefined"
      />
      <ExportObjects
        @closed="closeExportObjects"
        v-if="exportObjects !== null && exportObjects.length > 0"
      />
      <BadgeProcess
        @closed="closeBadgeProcess"
        v-if="badgeProcess !== null"
      />
      <BulkOperationDialog
        @close="closeBulkOperation"
        v-if="bulkOperation !== null"
      />
      <ActionSuccess
        @closed="closeActionSuccess"
        v-if="actionSuccess !== null"
      />
      <ActionError
        @closed="closeActionError"
        v-if="actionError !== null"
      />
    </v-main>
    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="black"
      v-if="false"
    >
      {{ $t('anUpdateIsAvailable') }}
      <v-btn
        text
        @click="refreshApp"
      >
        {{ $t('update') }}
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppBarTab from "./components/AppBarTab"
import ExportObjects from "./components/ExportObjectsDialog"
import BadgeProcess from "./components/BadgeProcess"
import Sidebar from "./components/navigation/Sidebar"
import BulkOperationDialog from "./components/bulkOperations/BulkOperationDialog"
import PWAUpdate from "./mixins/update"
import ContextOverlay from "./components/common/ContextOverlay.vue"
import CallToAction from "./components/common/CallToAction"
import ActionSuccess from "@/components/common/ActionSuccess"
import ActionError from "@/components/common/ActionError"
import MutationDialog from "@/components/common/MutationDialog"
import GenericDialog from "@/components/common/GenericDialog"

export default {
  name: "App",
  components: {
    ActionError,
    ActionSuccess,
    BulkOperationDialog,
    BadgeProcess,
    AppBarTab,
    ExportObjects,
    Sidebar,
    ContextOverlay,
    CallToAction,
    MutationDialog,
		GenericDialog
  },
  mixins: [PWAUpdate],
  data: () => ({
    menu: false,
		enablingSalesCloudPay: false,
  }),
  computed: {
		routes() {
			return this.$store.state.routes
		},
		initialDataProcessed() {
			return this.$store.state.initialDataProcessed
		},
    isShowAppBar() {
      return this.$store.getters.isShowAppBar(this.$vuetify.breakpoint.mobile)
    },
    // showAppBar && user && !inApp
    computedMainStyle() {

      if(this.user === null) {
        return {
          padding: 0
        }
      }

      // In App
      if(this.inApp) {
        return {
          paddingTop: '0'
        }
      }

      // In iFrame
      if(this.inIframe) {
        if(this.showAppBar === true) {
          if(Array.isArray(this.appBarTabs) && this.appBarTabs.length > 0) {
            return {
              paddingTop: '114px'
            }
          }
          return {
            paddingTop: '64px'
          }
        }
        return {
          paddingTop: '0'
        }
      }

      // Not in iFrame
      if(!this.inIframe) {
        let paddingLeft = '295px'
        let paddingTop = '0'


        if(this.$store.state.minimizeSidebar === true) {
          paddingLeft = '76px'
        }

        if(this.appBarTabs !== null && this.appBarTabs.length > 0 && this.showAppBar === true) {
          paddingTop = '114px'
        } else if(this.showAppBar === true) {
          paddingTop = '64px'
        }

				if(this.$vuetify.breakpoint.mobile) {
					paddingLeft = '0px'
				}

        return {
          paddingLeft,
          paddingTop
        }
      }

      if(this.appBarTabs !== null && this.appBarTabs.length > 0 && this.showAppBar === true) {
        return {
          paddingTop: '114px'
        }
      }
      else if(this.showAppBar === false) {
          return {
            paddingTop: '0'
          }
        }

      return {
        paddingTop: '70px'
      }
    },
		userIsLoggedIn() {
			return this.$store.getters.userIsLoggedIn
		},
    dataToMutate() {
      return this.$store.state.dataToMutate
    },
		genericDialog() {
			return this.$store.state.genericDialog
		},
    selectedCallToAction() {
      return this.$store.state.selectedCallToAction
    },
    contextOverlay() {
      return this.$store.state.contextOverlay
    },
    organizationUUID() {
      return this.$store.state.organizationUUID
    },
    inApp() {
      return this.$store.state.inApp
    },
		canEnableSalesCloudPay() {
      return this.$store.state.canEnableSalesCloudPay
    },
		isPendingSalesCloudPayOnboardingCompletion() {
      return this.$store.state.isPendingSalesCloudPayOnboardingCompletion
    },
    billingAccountState() {
      return this.$store.state.billingAccountState
    },
    appBarMarketing() {
      return this.$store.state.appBarMarketing
    },
    exportObjects() {
      return this.$store.state.exportObjects
    },
    badgeProcess() {
      return this.$store.state.badgeProcess
    },
    bulkOperation() {
      return this.$store.state.bulkOperation
    },
    actionSuccess() {
      return this.$store.state.actionSuccess
    },
    actionError() {
      return this.$store.state.actionError
    },
    breadcrumbs: function () {
      const paths = this.$route.path.split("/")
      const crumbs = []

      for (let i in paths) {
        let title = ""

        if (paths[i] === "") {
          title = "Home"
        } else {
          title = paths[i]
        }

        crumbs.push({
          text: title,
          disabled: false,
          href: paths[i],
        })
      }

      return []
    },
    showAppBar: function () {
      return this.$store.getters.showAppBar
    },
    renderSideBar: function () {
      return this.$store.getters.renderSidebar
    },
    showAppBarSearchField: {
      set: function (show) {
        this.$store.commit("updateShowAppBarSearchField", show)
      },
      get: function () {
        return this.$store.state.showAppBarSearchField
      },
    },
    primaryColor: function () {
      return this.$store.getters.primaryColor
    },
    accentColor: function () {
      return this.$store.getters.accentColor
    },
    appBarChangeDisplay: function () {
      return this.$store.state.appBarChangeDisplay
    },
    appBarExport: function () {
      return this.$store.state.appBarExport
    },
    exportFileName: function () {
      return this.$store.state.exportFileName ?? this.$store.state.exportStore.exportFileName
    },
    exportXlsHtml () {
      return this.$store.state.exportStore.exportXlsHtml
    },
    exportIsCompressed () {
      return this.$store.state.exportStore.exportIsCompressed ? 'true' : 'false'
    },
    exportPdfHtml: function () {
      return this.$store.state.exportPdfHtml
    },
    searchQuery: {
      set: function (query) {
        this.$store.commit("updateSearchQuery", query)
      },
      get: function () {
        return this.$store.state.searchQuery
      },
    },
    inIframe: {
      set: function (inIframe) {
        this.$store.commit("updateInIframe", inIframe)
      },
      get: function () {
        return this.$store.state.inIframe
      },
    },
    showSidebar: {
      set: function (newValue) {
        this.$store.commit("updateShowSidebar", newValue)
      },
      get: function () {
        return this.$store.state.showSidebar
      },
    },
    selectedAppBarTab: {
      set: function (newValue) {
        this.$store.commit("updateSelectedAppBarTab", newValue)
      },
      get: function () {
        return this.$store.state.selectedAppBarTab
      },
    },
    showPlatforms: {
      set: function (newValue) {
        this.$store.commit("updateShowPlatforms", newValue)
      },
      get: function () {
        return false
      },
    },
    accessToken: {
      set(newValue) {
        this.$store.commit("updateAccessToken", newValue)
      },
      get() {
        return this.$store.state.accessToken
      },
    },
    ls: {
      set: function (newValue) {
        this.$store.commit("updateLs", newValue)
      },
      get: function () {
        return this.$store.state.ls
      },
    },
    lo: {
      set(newValue) {
        this.$store.commit("updateLo", newValue)
				try {
					this.$store.commit('updateSelectedOrganization', atob(newValue))
				}
				catch(e) {
					// Do nothing
				}
      },
      get: function () {
        return this.$store.state.lo
      },
    },
    appBarSearchChips: function () {
      return this.$store.getters.appBarSearchChips
    },
    appBarTabs: function () {
      return this.$store.state.appBarTabs
    },
    appBarFAB: function () {
      return this.$store.state.appBarFAB
    },
    navigationItems() {
      return this.$store.getters.navigationItems
    },
    appBarTitle: {
      get() {
        return this.$store.state.appBarTitle
      },
      set(newValue) {
        this.$store.commit("updateAppBarTitle", newValue)
      },
    },
    user() {
      return this.$store.state.user
    },
    platforms() {
      return this.$store.state.platforms
    },
		authIsLoading() {
			return this.$auth.loading
		},
		authIsAuthenticated() {
			return this.$auth.isAuthenticated
		}
  },
  methods: {
		enableSalesCloudPay() {
			this.enablingSalesCloudPay = true
			return this.$store.dispatch('enableSalesCloudPay').then(result => {

				if(result !== null && result !== undefined && result.success === true) {
					window.location = result.onboardingUrl
				}

			}).catch(() => {
				// Do nothing
			})
			.finally(() => {
				this.enablingSalesCloudPay = false
			})
		},
		getUser() {
			return this.$store.dispatch('getInitialData').then(initialData => {
				return initialData
			})
		},
		validateSession() {
			return this.$store.dispatch('isSessionValid', {}).then(isValid => {
				return isValid
			}).catch(() => {
				return false
			})
		},
    localStorageChanged() {
      let accessToken = localStorage.getItem('accessToken')

      if(accessToken !== null && accessToken  !== undefined) {
        this.accessToken = JSON.parse(accessToken)
      }
    },
    getInitialData() {
      this.$store.dispatch("getInitialData")
    },
    closeExportObjects() {
      this.$store.commit("updateExportObjects", null)
    },
    closeBadgeProcess() {
      this.$store.commit("updateBadgeProcess", null)
    },
    closeBulkOperation() {
      this.$store.commit("updateBulkOperation", null)
    },
    closeActionSuccess() {
      this.$store.commit("updateActionSuccess", null)
    },
    closeActionError() {
      this.$store.commit("updateActionError", null)
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar
      window.parent.postMessage(
        {
          type: "toggleSidebar",
        },
        "*"
      )
    },
    xlsSubmit() {
      // Looks like this is required to trigger the export
    },
    pdfSubmit() {
      alert("hello")
    },
    toggleSearch() {
      this.showAppBarSearchField = !this.showAppBarSearchField
    },
    closeSearch() {
      this.searchQuery = null
      this.showAppBarSearchField = false
    },
    reload() {
      const location = this.$route.fullPath

      this.$router.replace("/")

      this.$nextTick(() => this.$router.replace(location))
    },
		evaluateAuth($auth) {

			if(this.inApp || this.inIframe || document.location.href.includes('ls=')) {
				console.log('aborting login')
				return
			}

			if(!$auth.isAuthenticated && !$auth.loading) {
				$auth.loginWithRedirect()
			}
			else if($auth.isAuthenticated && !$auth.loading) {
				console.log('gettingTokens')
				$auth.getTokenSilently().then(token => {
					this.$store.commit('updateAccessToken', token)
					this.$store.commit('updateShowAppBar', false)
					this.getUser().then(() => {
						//this.$router.push({path: this.$route.path})
					})          
				}).catch(error => {
					console.error(error)
				})
			}
			else {
				console.log($auth.isAuthenticated)
				console.log($auth.loading)
				console.log('no condition matched')
			}
		}
  },
  watch: {
		authIsLoading() {
			this.evaluateAuth(this.$auth)
		},
		routes(newRoutes) {
			if (Array.isArray(newRoutes) && this.$router.options.routes.length < 8) {
				for (let i = 0; i < newRoutes.length; i++) {
					const route = newRoutes[i]

					// Handling undefined and null
					if(typeof route === 'undefined' || route === null) {
						continue
					}

					// Set up the route object
					const routeObject = {}
					routeObject.name = route.title
					routeObject.path = '/' + route.path
					routeObject.component = () => import(/* webpackChunkName: "title" */ "./views/" + route.path + ".vue")
					routeObject.meta = {}
					routeObject.meta.isAppBarExtended = true
					routeObject.meta.type = route.type

					// Add the route object
					this.$router.addRoute(routeObject)

					// Handling sub routes
					if(Array.isArray(route.routes)) {
						for(let n = 0; n < route.routes.length; n++) {
							const subRoute = route.routes[n]

							// No sub route
							if(typeof subRoute === 'undefined' || subRoute === null) {
								continue
							}

							// No sub route path string
							if(typeof subRoute.path !== 'string') {
								continue
							}

							// Create a slug
							const slug = subRoute.path.replace(/:/g, '')

							// Prepare the sub route object
							const subRouteObject = {}
							subRouteObject.name = subRoute.title // ATTN: This causes 'duplicated route name' warning
							subRouteObject.path = '/' + subRoute.path
							subRouteObject.component = () => import(/* webpackChunkName: "title" */ "./views/" + slug + ".vue")

							// Add the sub route object
							this.$router.addRoute(subRouteObject)
						}
					}
				}
			}
		},
		initialDataProcessed(newValue, oldValue) {
			if(newValue === true && oldValue === false) {
				let nextContinue = this.$route.query.continue

				if(nextContinue !== '' && document.location.hash.length > 2) {
					nextContinue = document.location.hash.substr(1)
				}

				if(nextContinue !== null && nextContinue !== undefined && nextContinue !== '' && nextContinue !== '/') {
					this.$router.push({path: nextContinue})
				}
				else {
					this.$router.push({path: '/dashboard'})
				}
			}
		},
    primaryColor(newValue) {
      this.$vuetify.theme.themes.light.primary = newValue
      document.querySelector('meta[name="theme-color"]').setAttribute("content", newValue)
    },
    organizationUUID(organizationUUID) {
      const selectedLocation = localStorage.getItem(
        organizationUUID + ":selectedLocation"
      )

      if (selectedLocation !== null && selectedLocation !== undefined) {
        this.$store.commit("updateSelectedLocation", selectedLocation)
      } else if (
        this.$route.query.l !== null &&
        this.$route.query.l !== undefined
      ) {
        this.$store.commit("updateSelectedLocation", this.$route.query.l)
      }
    },
    exportXlsHtml: function (newValue) {
      if (newValue !== null) {
        this.$nextTick(function () {
          this.$refs.exportXLSForm.submit()
        })
      }
    },
    exportPdfHtml: function (newValue) {
      if (newValue !== null) {
        this.$nextTick(function () {
          this.$refs.exportPDFForm.submit()
        })
      }
    },
    $route: function (to, from) {
      if (from.query.ls) {
        this.ls = from.query.ls
      }

      if (to.query.ls) {
        this.ls = to.query.ls
      }

      if (from.query.lo) {
        this.lo = from.query.lo
      }

      if (to.query.lo) {
        this.lo = to.query.lo
      }

      if (to.meta && to.meta.type && to.meta.type === "nativeHidden") {
        this.$store.commit("updateShowAppBar", false)
        this.$store.commit("updateShowPlatforms", false)
        this.$store.commit("updateShowSidebar", false)
      }

      this.$store.commit("updateAppBarTabs", null)
      this.$store.commit("updateSelectedAppBarTab", null)
      this.$store.commit("updateAppBarTitle", to.name)
      this.$store.commit("updateAppBarFab", null)
    },
    lo: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getInitialData()
      }
    },
  },
  created() {
    const date = new Date()
    date.setMonth(date.getMonth() - 1)
    const startTimeInMillis = date.getTime()

    this.$store.commit("updateStartTimeInMillis", startTimeInMillis)
    this.$store.commit("updateEndTimeInMillis", Date.now())

    const searchParams = new URLSearchParams(document.location.search)
    const accessToken = searchParams.get("accessToken")

    if (accessToken !== null && accessToken !== undefined) {
      this.$store.commit("updateAccessToken", accessToken)
    }

    try {
      if (window.self !== window.top) {
        this.inIframe = true
      }
    } catch (e) {
      this.inIframe = true
    }

    if (searchParams.get("inApp")) {
      this.$store.commit("updateInApp", true)
    }

    if (searchParams.get("showAppBar") === "false") {
      this.$store.commit("updateShowAppBar", false)
    }

		if(searchParams.get("inIframe") === "true") {
			this.inIframe = true
		}
  },
  mounted() {

    try {
      // eslint-disable-next-line no-undef
      if (
        typeof SalesCloud !== "undefined" &&
        SalesCloud !== undefined &&
        SalesCloud !== null
      ) {
        // eslint-disable-next-line no-undef
        SalesCloud.lockRefresh()
      }
    }
    catch(e) {

    }

    const selectedOrganization = sessionStorage.getItem('selectedOrganization')

		if(selectedOrganization !== null && selectedOrganization !== undefined) {
			this.$store.commit('updateSelectedOrganization', selectedOrganization)
		}

		if(this.user === null && this.inApp === false && this.inIframe === false && !document.location.href.includes('ls=')) {
				this.evaluateAuth(this.$auth)
		}

    //this.$vuetify.theme.dark = true

    window.addEventListener('storage', this.localStorageChanged)
  },
}
</script>

<style>
.row {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
#mutation-dialog {
  max-width: 500px;
}

/*
.v-application .v-card.sc-shadow {
  box-shadow: 0px 8px 28px rgba(33, 75, 82, 0.09);
}
*/

</style>
