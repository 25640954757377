// src/store/modules/orders.js
export default {
  namespaced: true,
  state: {
    ordersListRequiresRefresh: false
  },
  mutations: {
    updateOrdersListRequiresRefresh(state, ordersListRequiresRefresh) {
        state.ordersListRequiresRefresh = ordersListRequiresRefresh
    }
  }
}
