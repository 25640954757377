import pako from 'pako'

const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB

export default {
  namespaced: true,
  state: {
    exportFileName: null,
    exportXlsHtml: null,
    exportHtmlHeader: '<html><head><meta http-equiv="Content-type" content="text/html; charset="utf-8" /><meta charset="UTF-8" /><style>body {font-family: Arial, sans-serif;} table {width: 100%; margin-bottom: 40px;} th {text-align: left; padding: 5px;} td {text-align: left; padding: 5px;} button { display: none; } .table td, .table th { padding: .75rem; vertical-align: top; border-top: 1px solid #eceeef; } .table-striped > tbody > tr:nth-child(odd) { background-color: rgba(0,0,0,0.025); background-clip: padding-box; } caption { caption-side: top; background: #fff; color: #333; font-size: 1rem; } .text-right { text-align: right;} .text-left { text-align: left;}</style></head><body>',
    exportHtmlFooter: '</body></html>',
    exportIsCompressed: false
  },
  mutations: {
    exportXLS(state, payload) {
      let html = state.exportHtmlHeader
      html += payload.html
      html += state.exportHtmlFooter

      state.exportFileName = payload.fileName
      
      if(html.length > MAX_FILE_SIZE) {
        const compressed = pako.deflate(html)
        const base64 = Buffer.from(compressed).toString('base64')
        
        state.exportXlsHtml = base64
        state.exportIsCompressed = true
        return
      }      

      state.exportXlsHtml = html
    }
  }
}
